import React, {useContext} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {LoaderContext} from "../../context/loader/loader.context";

const LoaderOverlay = (props) => {
    const {state} = useContext(LoaderContext)

    return (
        <LoadingOverlay
            active={state.activeLoaderCount > 0}
            spinner
            text={'Loading'}
            styles={{
                overlay: (base) => ({
                    ...base,
                    position: 'fixed',
                    zIndex: 99999999999999999999,
                }),
            }}
        >
            {props.children}
        </LoadingOverlay>
    )
}

export default LoaderOverlay
