import React, {useReducer} from 'react';
import {LoaderContext} from "./loader.context";

type Action =
    | { type: 'SHOW_LOADER'; payload: any }
    | { type: 'HIDE_LOADER'; payload: any }

function reducer(state: any, action: Action): any {
    switch (action.type) {
        case 'SHOW_LOADER':
            return { activeLoaderCount: state.activeLoaderCount + 1 };
        case 'HIDE_LOADER':
            return { activeLoaderCount: state.activeLoaderCount - 1 };
        default:
            return state;
    }
}

type LoaderProviderProps = {
    initData: any;
};

export const LoaderProvider: React.FunctionComponent<LoaderProviderProps> = ({
    children,
    initData
}) => {
    const [state, dispatch] = useReducer(reducer, { ...initData });

    return (
        <LoaderContext.Provider value={{ state, dispatch }}>
            {children}
        </LoaderContext.Provider>
    );
};
