// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PROFILE = '/profile';
export const PRODUCTS = '/products';
export const SUGGESTED_PRODUCTS = '/suggested-products';
export const INVENTORY = '/inventory';
export const INVENTORY_MOVES = '/inventory-moves';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const EMAIL_VERIFICATION = '/email-verification/:id';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const SHOPPING_BUNDLES = '/orders';
export const FULFILL_ORDER = '/orders/:id/fulfill';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
