import { ADD_MERCHANT, REMOVE_MERCHANT } from "../../constants/redux";

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_MERCHANT:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_MERCHANT:
      return {};
    default:
      return state;
  }
};
