import { combineReducers } from "redux";
import authReducer from "./auth/Reducer";
import meReducer from "./me/Reducer";
import merchantReducer from "./merchant/Reducer";

const Reducers = combineReducers({
  authReducer,
  merchantReducer,
  meReducer,
});

export default Reducers;
