import React, {lazy, Suspense} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {
    LOGIN,
    REGISTER,
    PRODUCTS,
    SUGGESTED_PRODUCTS,
    CATEGORY,
    DASHBOARD,
    ORDERS,
    SETTINGS,
    CUSTOMERS,
    COUPONS,
    STAFF_MEMBERS,
    SITE_SETTINGS,
    INVENTORY,
    INVENTORY_MOVES,
    SHOPPING_BUNDLES,
    PROFILE,
    FULFILL_ORDER, EMAIL_VERIFICATION,
} from 'settings/constants';
import {InLineLoader} from 'components/InlineLoader/InlineLoader';
import InventoryMoves from "./containers/InventoryMoves/InventoryMoves";
import {useSelector} from "react-redux";
import _ from "lodash";

const Products = lazy(() => import('containers/Products/Products'));
const Profile = lazy(() => import('containers/Profile/Profile'));
const SuggestedProducts = lazy(
    () => import('containers/SuggestedProducts/SuggestedProducts'),
);
const InventoryInterface = lazy(() => import('containers/Inventory/InventoryInterface'));

const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Category/Category'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const ShoppingBundles = lazy(() => import('containers/ShoppingBundles/ShoppingBundles'));
const Settings = lazy(() => import('containers/Settings/Settings'));
const SiteSettingForm = lazy(
    () => import('containers/SiteSettingForm/SiteSettingForm'),
);
const StaffMembers = lazy(() => import('containers/StaffMembers/StaffMembers'));
const Customers = lazy(() => import('containers/Customers/Customers'));
const Coupons = lazy(() => import('containers/Coupons/Coupons'));
const Login = lazy(() => import('containers/Login/Login'));
const Register = lazy(() => import('containers/Register/Register'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));
const FulfillOrder = lazy(() => import('containers/ShoppingBundles/ShoppingBundleScannerWizard'));
const EmailVerification = lazy(() => import("containers/EmailVerification/EmailVerification"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({children, ...rest}) {

    const isAuthenticated = useSelector((state) =>
        _.get(state, "authReducer.accessToken", null)
    );

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}

const Routes = () => {

    return (
        <Suspense fallback={<InLineLoader/>}>
            <Switch>
                <PrivateRoute exact={true} path={DASHBOARD}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader/>}>
                            <Dashboard/>
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute exact={true} path={PROFILE}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader/>}>
                            <Profile/>
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={INVENTORY}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader/>}>
                            {/*<Inventory/>*/}
                            <InventoryInterface />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>

                {/*<PrivateRoute path={PRODUCTS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <Products/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute path={CATEGORY}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <Category/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute path={ORDERS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <Orders/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                <PrivateRoute exact path={SHOPPING_BUNDLES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader/>}>
                            <ShoppingBundles/>
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                {/*<PrivateRoute path={CUSTOMERS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <Customers/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute path={COUPONS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <Coupons/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute path={SETTINGS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <Settings/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute path={STAFF_MEMBERS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <StaffMembers/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                {/*<PrivateRoute path={SITE_SETTINGS}>*/}
                {/*    <AdminLayout>*/}
                {/*        <Suspense fallback={<InLineLoader/>}>*/}
                {/*            <SiteSettingForm/>*/}
                {/*        </Suspense>*/}
                {/*    </AdminLayout>*/}
                {/*</PrivateRoute>*/}
                <PrivateRoute exact path={FULFILL_ORDER}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader/>}>
                            <FulfillOrder />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <Route path={LOGIN}>
                    <Login/>
                </Route>
                <Route path={REGISTER}>
                    <Register/>
                </Route>
                <Route path={EMAIL_VERIFICATION}>
                    <EmailVerification/>
                </Route>
                <Route component={NotFound}/>
            </Switch>
        </Suspense>
    );
};

export default Routes;
