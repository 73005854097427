// AUTH
export const ADD_AUTH = "ADD_AUTH";
export const REMOVE_AUTH = "REMOVE_AUTH";

// ME
export const ADD_ME = "ADD_ME";
export const REMOVE_ME = "REMOVE_ME";

//MERCHANT
export const ADD_MERCHANT = "ADD_MERCHANT";
export const REMOVE_MERCHANT = "REMOVE_MERCHANT";
